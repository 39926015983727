/* src/AddEntry.module.css */
.container {
    font-family: 'Arial, sans-serif';
    background-color: #f0f2f5;
    min-height: 100vh;
  }
  
  .content {
    max-width: 600px;
    margin: 80px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    text-align: center;
    font-size: 28px;
    color: #333;
    margin-bottom: 30px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formGroup label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
    color: #555;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formGroup textarea {
    resize: vertical;
    height: 100px;
  }
  
  .addButton {
    padding: 12px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .addButton:hover {
    background-color: #0069d9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .content {
      margin: 60px 20px;
      padding: 15px;
    }
  
    .title {
      font-size: 24px;
    }
  }
  