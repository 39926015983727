/* src/PasswordGenerator.module.css */
.generator {
    margin-top: 30px;
    padding: 20px;
    background-color: #e9ecef;
    border-radius: 6px;
  }
  
  .title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .option label {
    margin-left: 8px;
    color: #555;
  }
  
  .option input[type="number"] {
    width: 60px;
    padding: 6px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .option input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .generateButton {
    margin-top: 15px;
    padding: 10px;
    background-color: #17a2b8;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .generateButton:hover {
    background-color: #138496;
  }
  