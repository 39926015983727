/* src/PasswordField.module.css */
.passwordField {
    display: flex;
    align-items: center;
  }
  
  .password {
    font-family: 'Courier New', monospace;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .toggleButton {
    padding: 6px 12px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .toggleButton:hover {
    background-color: #0069d9;
  }
  